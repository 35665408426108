body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #24292a;   */
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  background: linear-gradient(#24292a, #24292e) 50%, rgba(36, 41, 46, 0);
}

.hero {
  /* margin-top: 100px; */
  padding: 100px 10px 10px 10px;
}

.main > .container {
  display: grid;
  grid-template-columns: auto 360px;
  grid-template-areas: "demopanel controlpanel"
}

.demo-panel {
  grid-area: demopanel;
}

.control-panel {
  grid-area: controlpanel;  
}


@media (max-width: 900px) {
  .main > .container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 
    "demopanel"
    "controlpanel"    
  }
}

.primary-button {
  display: inline-block;
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  font-weight: 500;
  font-family: inherit;
  line-height: inherit;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: white;
  background-color: #0066ff;
  border: 0;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.secondary-button {
  display: inline-block;
  margin: 0px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  font-family: inherit;
  line-height: inherit;
  text-align: center;
  text-decoration: none;
  border: 0px;
  border-radius: 6px;
  appearance: none;
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #444d56;
}

.primary-button:hover {
  opacity: 0.8;
}

.secondary-button:hover {
  opacity: 0.8;
}

.empty-message {
  display: flex;
  align-items: center;
  justify-content: center;
}