.widget-code-container {
  padding: 10px;
  margin: 0 16px; 
}
.widget-code-section > p {  
  /* inline-size: 60vw; */
  overflow-wrap: break-word;
}

/*   background-color: #30343C;
  max-width: 400px; */